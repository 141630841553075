import React, { useState } from 'react'
import axios from 'axios';
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// Components
import Layout from '../components/layout'

// Images
import contact from '~images/contact-us.svg'
import wave from '~images/wave-1-bottom-sm.svg'
import wave2 from '~images/wave-4-bottom-sm.svg'

const Contact = () => {

  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  const company = 'NA';
  const clientAmt = '0';
  const compState = 'NA';

  const [sending, setSending] = useState('false');

  const sendData = {
    subject: `Contact Us About ` + subject,
    name: name,
    email: email,
    phoneNumber: phoneNumber,
    message: message,
    dest: 'support',
    companyName: company,
    clientAmt: clientAmt,
    compState: compState

  }

  const headers = {
    'Content-Type': 'application/json'
  }

  const DemoRequestButton = event => {
    if (sending === 'false') {
      return <button type="submit" className="btn btn-primary btn-wide transition-3d-hover mb-4">Submit</button>;
    } else if (sending === 'true') {
      return <button type="button" className="btn btn-success disabled">Sending Message</button>;
    } else if (sending === 'sent') {
      return <button type="button" className="btn btn-info disabled">Your Message Has Been Sent</button>;
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    setSending('true');

    axios.post(`https://84fdl5xpqd.execute-api.us-west-2.amazonaws.com/dev`, sendData, { headers: headers })
      .then(res => {
        console.log(res);
        console.log(res.data);
        setSending('sent');
        setTimeout(() => { setSending('false'); }, 3000)
      })
  }

  const img = useStaticQuery(graphql`
    query {
      provo: file(relativePath: { eq: "provo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <main id="content" role="main">
        {/* Hero Section */}
        <div id="SVGhireUsBg" className="position-relative gradient-half-qvr-v3">
          <div className="container space-2 space-top-md-4 space-top-lg-3">
            <div className="row justify-content-lg-between align-items-center">
              <div className="col-md-6 col-lg-5">
                <h1 className="display-4 font-size-md-down-5 text-white mb-4"><strong>How can we <span className="text-warning">help</span> you?</strong></h1>
                <p className="lead text-white-70">We'd love to talk about how we can help you.</p>
              </div>
              <div className="col-md-6">
                <img className="img-fluid" src={contact} alt="person contacting support" />
              </div>
            </div>
          </div>

          {/* SVG Background */}
          <figure className="position-absolute right-0 bottom-0 left-0">
            <img style={{ width: '100%', height: '140px' }} src={wave} aria-hidden="true" alt="" />
          </figure>
          {/* End SVG Background Section */}
        </div>
        {/* End Hero Section */}

        {/* Contact Form Section */}
        <div className="container space-bottom-2 space-bottom-md-3">
          {/* Title */}
          <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-9">
            <h2 className="text-primary font-weight-normal">Send us a <span className="font-weight-semi-bold">message</span></h2>
            <p>Whether you have questions or you would just like to say hello.</p>
          </div>
          {/* End Title */}
          <div className="row justify-content-lg-between">
            <div className="col-lg-7 d-flex">
              {/* Contacts Form */}
              <form className="js-validate" onSubmit={handleSubmit}>
                <div className="row">
                  {/* Input */}
                  <div className="col-sm-6 mb-6">
                    <div className="js-form-message">
                      <label className="form-label">
                        Your name
                      <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" name="name" placeholder="Your Name" aria-label="Your Name" onChange={e => setName(e.target.value)} />
                    </div>
                  </div>
                  {/* End Input */}

                  {/* Input */}
                  <div className="col-sm-6 mb-6">
                    <div className="js-form-message">
                      <label className="form-label">
                        Your email address
                      <span className="text-danger">*</span>
                      </label>
                      <input type="email" className="form-control" name="email" placeholder="youremail@example.com" aria-label="youremail@example.com" onChange={e => setEmail(e.target.value)} />
                    </div>
                  </div>
                  {/* End Input */}

                  <div className="w-100"></div>

                  {/* Input */}
                  <div className="col-sm-6 mb-6">
                    <div className="js-form-message">
                      <label className="form-label">
                        Subject
                      <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" name="subject" placeholder="I would like a demo" aria-label="Subject" onChange={e => setSubject(e.target.value)} />
                    </div>
                  </div>
                  {/* End Input */}

                  {/* Input */}
                  <div className="col-sm-6 mb-6">
                    <div className="js-form-message">
                      <label className="form-label">
                        Your Phone Number
                      <span className="text-danger">*</span>
                      </label>
                      <input type="number" className="form-control" name="phone" placeholder="1-800-643-4500" aria-label="1-800-643-4500" onChange={e => setPhoneNumber(e.target.value)} />
                    </div>
                  </div>
                  {/* End Input */}
                </div>

                {/* Input */}
                <div className="js-form-message mb-6">
                  <label className="form-label">
                    How can we help you?
                  <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <textarea className="form-control" rows="4" name="text" placeholder="Hi there, I would like to ..." aria-label="Hi there, I would like to ..." onChange={e => setMessage(e.target.value)}></textarea>
                  </div>
                </div>
                {/* End Input */}

                <div className="text-center">
                  <div className="mb-2">
                    <DemoRequestButton />
                  </div>
                  {/* <button type="submit" className="btn btn-primary btn-wide transition-3d-hover mb-4">Submit</button> */}
                  <p className="small">We'll get back to you immediately.</p>
                </div>
              </form>
              {/* End Contacts Form */}
            </div>

            <BackgroundImage
              Tag="div"
              className="col-lg-5 d-flex align-items-end bg-img-hero gradient-overlay-half-dark-v1 min-height-550 rounded-top-pseudo"
              fluid={img.provo.childImageSharp.fluid}
            >
              {/* Contacts Info */}
              <div className="position-relative w-100 text-center p-5 pb-6">
                {/* Info */}
                <div className="mb-5">
                  <h2 className="text-white font-weight-semi-bold mb-0">QVR, <span className="text-primary">LLC</span></h2>
                  <div className="text-white-70">3300 Ashton Blvd, Ste 350</div>
                  <p className="text-white-70"> Lehi, UT 84043</p>
                </div>
                {/* End Info */}

                {/* Info */}
                <div className="mb-5 pb-5 space-bottom-3">
                  <h3 className="h6 text-primary">Phone number</h3>
                  <span className="d-block h5 text-white">(435) 232-4621</span>
                </div>
                {/* End Info */}

                {/* Subscribe Form */}
                {/* <form className="js-validate js-form-message">
                  <label className="sr-only" for="heroSubscribeSrEmail">Enter your email address</label>
                  <div className="input-group input-group-lg input-group-borderless input-group-pill">
                    <input type="email" className="form-control" name="email" id="heroSubscribeSrEmail" placeholder="Enter your email address" aria-label="Enter your email address" aria-describedby="heroSubscribeButton" required />
                    <div className="input-group-append">
                      <button className="btn btn-white text-primary" type="submit" id="heroSubscribeButton">
                        <span className="fas fa-paper-plane"></span>
                      </button>
                    </div>
                  </div>
                </form> */}
                {/* End Subscribe Form */}
              </div>
              {/* End Contacts Info */}

              {/* SVG Bottom Shape */}
              <figure className="position-absolute right-0 bottom-0 left-0">
                <img style={{ width: '100%', height: '80px' }} src={wave2} alt="design element" aria-hidden="true" />
              </figure>
              {/* End SVG Bottom Shape */}
            </BackgroundImage>
          </div>
        </div>
        {/* End Contact Form Section */}

      </main>
    </Layout>
  )
}

export default Contact
